<template>
  <BaseLayout wrapperClass="cart">
    <Breadcrumbs />
    <section class="cart pt-8">
      <b-container>
        <span class="page-title font-weight-normal d-none d-md-block mb-8">{{
          $t("shopping_basket")
        }}</span>
        <div
          class="d-flex justify-content-between align-items-center cart-info-and-btn"
        >
          <div
            class="items-info font-weight-normal d-block text-blue"
            v-html="getItemsInCart"
          ></div>
          <div class="options ml-20 d-none d-md-block">
            <b-link href="/">{{ $t("continue_shopping") }}</b-link>
            <b-button
              @click="$router.push('/checkout')"
              variant="info"
              class="text-light py-6 pr-20 pl-15"
              ><i class="lnr lnr-cart mr-25"></i
              ><i class="fa fa-check-circle bg-info"></i
              >{{ $t("continue_buying") }}</b-button
            >
          </div>
        </div>
        <b-row
          v-for="cartItem of cartItems"
          :key="cartItem.id"
          class="cart-item-row position-relative"
        >
          <b-link href="#" class="d-md-none mobile-cart-item-remove"
            ><i class="lnr lnr-cross"></i
          ></b-link>
          <b-col md="8" class="d-flex">
            <div
              class="img"
              :style="{
                backgroundImage: `url(${cartItem.product.image.medium})`,
              }"
            ></div>
            <div
              class="d-flex flex-column flex-md-row justify-content-between align-items-md-center flex-grow-1"
            >
              <div class="product text-blue pb-2 pb-md-10">
                <span class="product--name">{{ cartItem.product.name }}</span>
                <div v-if="typeof cartItem.configurable_options != 'undefined'">
                  <div
                    v-for="(option, index) of cartItem.configurable_options"
                    :key="index"
                    class="d-flex align-items-center mb-7"
                  >
                    <span class="size">{{ option.option_label }}: </span>
                    <span class="size-box">{{ option.value_label }}</span>
                    <span
                      :style="
                        'color: ' +
                        cartItem.configured_variant.DeliveryTime.color +
                        ' !important'
                      "
                      class="deliverytime"
                      v-if="
                        typeof cartItem.configured_variant.DeliveryTime !==
                        undefined
                      "
                      >{{ cartItem.configured_variant.DeliveryTime.long }}</span
                    >
                    <span
                      class="product--price text-blue d-md-none ml-20 config"
                      >{{
                        formatCurrency(
                          cartItem.prices.row_total_including_tax.value
                        )
                      }}</span
                    >
                  </div>
                  <b-link
                    class="change-price text-blue d-none d-md-inline-block"
                    @click="updateCartProduct(cartItem)"
                    >{{ $t("click_to_change_options") }}</b-link
                  >
                </div>
                <div v-if="typeof cartItem.configurable_options == 'undefined'">
                  <span
                    :style="
                      'color: ' +
                      cartItem.product.DeliveryTime.color +
                      ' !important'
                    "
                    class="deliverytime"
                    v-if="typeof cartItem.product.DeliveryTime !== undefined"
                    >{{ cartItem.product.DeliveryTime.long }}</span
                  >
                </div>
              </div>

              <div class="quantity-input d-flex ml-md-20">
                <b-link
                  href="#"
                  class="step-down-btn"
                  @click="quantityDown(cartItem.id, cartItem.quantity)"
                ></b-link>
                <input
                  type="number"
                  min="1"
                  :value="cartItem.quantity"
                  name="quantity"
                  ref="inputQuantity"
                  disabled
                />
                <b-link
                  href="#"
                  class="step-up-btn"
                  @click="quantityUp(cartItem.id, cartItem.quantity)"
                ></b-link>
              </div>
            </div>
          </b-col>
          <b-col
            md="4"
            class="d-none d-md-flex align-items-center justify-content-center position-relative pr-55"
          >
            <span class="product--price"
              >&nbsp;{{
                formatCurrency(cartItem.prices.row_total_including_tax.value)
              }}</span
            >
            <b-link
              href="#"
              @click="removeItem(cartItem.id)"
              class="lnr lnr-cross"
            ></b-link>
          </b-col>
        </b-row>
        <b-row class="discount-and-total">
          <b-col md="5" lg="4" class="coupon-discount">
            <span class="d-block">{{ $t("coupon_discount") }}</span>
            <form action="#" class="d-flex">
              <input
                type="text"
                class="flex-grow-1"
                name="discount-code"
                :placeholder="$t('fill_out_discount_code')"
                v-model="couponCode"
              />
              <b-button @click="addCoupon" variant="info">{{
                $t("apply")
              }}</b-button>
            </form>
            <div
              v-if="
                appliedCouponCodes &&
                appliedCouponCodes[0] &&
                appliedCouponCodes[0].code
              "
              class="pt-10"
            >
              {{ $t("applied_coupon_code") }}:
              {{ appliedCouponCodes[0].code }}
              <b-link
                href="#"
                @click="removeCouponCode()"
                class="lnr lnr-cross"
              ></b-link>
            </div>
            <div class="free_shipping">
              {{
                $t("free_shipping_msg", {
                  remain: formatCurrency(freeShippingAmount),
                })
              }}
            </div>
          </b-col>
          <b-col
            md="6"
            xl="5"
            offset-md="1"
            offset-lg="2"
            offset-xl="3"
            class="cost"
          >
            <span class="cost-heading font-weight-normal d-block">{{
              $t("totals")
            }}</span>
            <ul class="prices">
              <li
                v-if="defaultShipping != null"
                class="d-flex justify-content-between"
              >
                <span class="name"
                  >{{ $t("shipping_cost") }}
                  {{ defaultShipping.carrier_title }}</span
                >
                <span
                  v-if="typeof defaultShipping.amount != 'undefined'"
                  class="cost ml-10"
                  >{{ formatCurrency(defaultShipping.amount.value) }}</span
                >
              </li>
              <li
                class="d-flex justify-content-between"
                v-for="(discount, index) in cartTotalDiscounts"
                :key="index"
              >
                <span class="name">{{ discount.label }}</span>
                <span class="cost ml-10"
                  >- {{ formatCurrency(discount.amount.value) }}</span
                >
              </li>
              <li
                class="d-flex justify-content-between"
                v-for="(price, index) in cartTotalTaxPrices"
                :key="index"
              >
                <span class="name">{{ price.label }}</span>
                <span class="cost ml-10">{{
                  formatCurrency(price.amount.value)
                }}</span>
              </li>
            </ul>
            <div
              class="d-flex justify-content-between align-items-center mb-25"
            >
              <span class="total">{{ $t("total") }}</span>
              <span class="total-price ml-10 text-light-blue">{{
                formatCurrency(cartTotalPrice)
              }}</span>
            </div>
            <div class="time-delivery" v-if="getCartDeliveryTime">
              {{ $t("estimated_shipping_time") }}:
              <span
                :style="'color: ' + getCartDeliveryTime.color + ' !important'"
                >{{ getCartDeliveryTime.long }}</span
              >
            </div>
            <div class="d-flex justify-content-end align-items-center">
              <b-link to="/" class="mr-15 d-md-none link-underline">{{
                $t("continue_shopping")
              }}</b-link>
              <b-button
                @click="$router.push('/checkout')"
                variant="info"
                class="text-light py-6 pr-20 pl-15"
                ><i class="lnr lnr-cart mr-25"></i
                ><i class="fa fa-check-circle bg-info"></i>
                {{ $t("continue_buying") }}</b-button
              >
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section
      class="more-products"
      v-if="getCrossSellProducts && getCrossSellProducts.length > 0"
    >
      <div class="container">
        <span class="section-title d-block font-weight-normal text-center"
          >Klanten bestelden ook</span
        >
        <ClientOnly>
          <MoreProductsCarousel class="pt-5" :products="getCrossSellProducts" />
        </ClientOnly>
      </div>
    </section>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_thehague_fietskledingvoordeel/core/components/BaseLayout";
import Breadcrumbs from "@/base/core/components/Breadcrumbs";
import MoreProductsCarousel from "@/base/core//components/MoreProductsCarousel";
import ClientOnly from "vue-client-only";
import Cart from "@storefront/core/modules/cart/mixins";
import config from "@config";

export default {
  name: "CartPage",
  components: {
    BaseLayout,
    Breadcrumbs,
    MoreProductsCarousel,
    ClientOnly,
  },
  mixins: [Cart],
  data() {
    return {
      couponCode: "",
    };
  },
  computed: {
    freeShippingAmount() {
      return this.$store.getters["cart/getFreeShippingAmount"];
    },
    getItemsInCart() {
      let items = this.$t("items_in_cart", { numberOf: this.cartCount });
      items =
        '<i class="lnr lnr-cart mr-7"></i>' +
        items.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
      return items;
    },
    getCartDeliveryTime() {
      return this.$store.getters["cart/getCartDeliveryTime"];
    },
    appliedCouponCodes() {
      return this.$store.getters["cart/getAppliedCouponCodes"];
    },
    getCrossSellProducts: {
      get() {
        return this.$store.getters["sale/getCrossSellProducts"];
      },
      set() {},
    },
  },
  methods: {
    async addCoupon() {
      if (this.couponCode != "") {
        const retval = await this.$store.dispatch("cart/addCouponCode", {
          code: this.couponCode,
        });
        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("coupon_code"),
            text: this.$t("coupon_code_ok", { code: this.couponCode }),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.appliedCouponCode = this.couponCode;
          this.couponCode = "";
        }
      }
    },
    getCartItemUrl(url_key) {
      return "/" + url_key;
    },
    updateCartProduct(product) {
      this.removeItem(product.id);
      this.$router.push("/" + product.product.url_key);
    },
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      });
      return formatter.format(value);
    },
    async removeCouponCode() {
      await this.$store.dispatch("cart/removeCouponCode");
    },
    async updateCrossSellProducts() {
      var skuList = [];
      this.cartItems?.forEach((item) => {
        skuList.push(item.product.sku);
      });
      if (config.helloRetail && config.helloRetail.enabled) {
        await this.$store.dispatch("sale/loadCrossSellProducts", {
          skus: skuList,
        });
      } else {
        await this.$store.dispatch("sale/loadCrossSellForCart", {
          skus: skuList,
        });
      }
      setTimeout(() => {
        this.getCrossSellProducts =
          this.$store.getters["sale/getCrossSellProducts"];
      }, 1000);
    },
  },
  mounted() {
    this.updateCrossSellProducts();
  },
  watch: {
    cartItems() {
      this.updateCrossSellProducts();
    },
  },
};
</script>

